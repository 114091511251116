/** @jsx jsx */

import { MDXProvider } from '@mdx-js/react'
import { graphql } from 'gatsby'
import { getImage, IGatsbyImageData } from 'gatsby-plugin-image'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { FC } from 'react'
import { jsx } from 'theme-ui'

import ContinueReading from '../../components/continue-reading'
import DynamicImage from '../../components/dynamic-image'
import Halo from '../../components/halo'
import Intro from '../../components/intro'
import Layout from '../../components/layout'
import SignUpCallToAction from '../../components/sign-up-call-to-action'
import Table from '../../components/table'
import UniversalLink from '../../components/universal-link'
import { VideoProps } from '../../components/video'
import mdxStyles from '../../constants/mdx-styles'

const PageBreak: FC = () => <div className="pagebreak" />

const Video: FC<Pick<VideoProps, 'caption' | 'poster' | 'src'>> = ({ poster, src, caption: children }) => {
  return (
    <figure>
      <video {...{ poster, src }} controls />
      <figcaption {...{ children }} />
    </figure>
  )
}

interface CaseStudyTemplateProps {
  data: {
    contentfulCaseStudy: {
      author: {
        image: IGatsbyImageData
        name: string
        title: string
        company: string
      }
      description: {
        childMarkdownRemark: {
          html: string
        }
      }
      body: {
        childMarkdownRemark: {
          html: string
        }
        childMdx: {
          body: string
        }
      }
      heroImage: any
      logo: {
        title: string
        file: {
          url: string
        }
      }
      slug: string
      tags: string[]
      title: string
    }
    allContentfulEntry: {
      edges: {
        node: {
          title: string
          gatsbyPath: string
        }
      }
    }
  }
}

const CaseStudyTemplate: FC<CaseStudyTemplateProps> = ({ data }) => {
  const caseStudy = data.contentfulCaseStudy
  const { body, description, logo, title, heroImage, slug } = caseStudy
  const bodyMdx = body.childMdx.body
  const descriptionHtml = description.childMarkdownRemark.html
  const postImage = heroImage ? getImage(heroImage) : undefined
  const haloImage = postImage?.images?.fallback?.src
    ? `https:${postImage?.images?.fallback?.src?.split(`?`)[0]}?w=1200&h=628&q=50&fm=png`
    : undefined

  const otherPosts = data.allContentfulEntry?.edges
    .filter(({ node: { title } }) => title && title !== caseStudy.title)
    .sort((a, b) => {
      const aDate = new Date(a.node.date)
      const bDate = new Date(b.node.date)

      return bDate.getTime() - aDate.getTime()
    })
    .slice(0, 3)

  const haloDescription = descriptionHtml.replace(`<p>`, ``).replace(`</p>`, ``)

  return (
    <Layout includeVisual={false}>
      <Halo
        title={`${title} case study`}
        description={haloDescription}
        root="../../"
        image={haloImage}
        route={`/case-studies/${slug}`}
      />
      <Intro
        title={title}
        description={descriptionHtml}
        // TODO: Add date to Contentful
        secondaryDescription={title === `EquipmentShare Carta Cross` ? `November 2021` : undefined}
        image={{ src: logo.file.url, alt: logo.title, sx: { display: `block`, maxWidth: `48px`, mb: 3 } }}
      />
      <section sx={{ bg: `white_slate8` }}>
        <div sx={{ maxWidth: `var(--maxContentWidth)`, mx: `auto`, px: 4, py: 5 }}>
          <div sx={mdxStyles}>
            <MDXProvider components={{ DynamicImage, PageBreak, Table, UniversalLink, Video }}>
              <MDXRenderer>{bodyMdx}</MDXRenderer>
            </MDXProvider>
          </div>
        </div>
      </section>

      <ContinueReading containerStyles={{ mt: 3, mb: 4 }} posts={otherPosts} />
      <SignUpCallToAction />
    </Layout>
  )
}

export default CaseStudyTemplate

export const pageQuery = graphql`
  query ($slug: String!) {
    contentfulCaseStudy(slug: { eq: $slug }) {
      author {
        company

        image {
          gatsbyImageData(height: 88, width: 88)
        }

        name
        title
      }

      body {
        childMdx {
          body
        }
      }

      description {
        childMarkdownRemark {
          html
        }
      }

      heroImage {
        gatsbyImageData(width: 936)
      }

      logo {
        file {
          url
        }

        title
      }

      slug
      tags
      title
    }

    allContentfulEntry {
      edges {
        node {
          ... on ContentfulBlogPost {
            date: publishDate

            description {
              childMarkdownRemark {
                html
              }
            }

            gatsbyPath(filePath: "/blog/{ContentfulBlogPost.slug}")

            heroImage {
              gatsbyImageData(width: 48)
              title
            }

            publishDate(formatString: "MMMM D, YYYY")
            tags
            title
          }

          ... on ContentfulExternalPost {
            date: publishDate

            description {
              childMarkdownRemark {
                html
              }
            }

            heroImage {
              gatsbyImageData(width: 48)
              title
            }

            link
            publishDate(formatString: "MMMM D, YYYY")
            tags
            title
          }

          ... on ContentfulCaseStudy {
            date: publishDate

            description {
              childMarkdownRemark {
                html
              }
            }

            gatsbyPath(filePath: "/case-studies/{ContentfulCaseStudy.slug}")

            logo {
              gatsbyImageData(width: 48)
              title
            }

            publishDate
            tags
            title
          }
        }
      }
    }
  }
`
